.tree {
  * {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  ul {
    margin-top: 40px !important;
  }
  ul li {
    margin-left: 35px;
    margin-top: 22px;
    position: relative;
    padding-left: 25px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #828282;

    display: flex;
    justify-content: space-between;
    max-width: 290px;
  }
  ul li::before {
    content: ' ';
    position: absolute;
    width: 1.5px;
    background-color: #a5a5a5;
    top: -42px;
    bottom: -33px;
    left: -10px;
  }
  body > ul > li:first-child::before {
    top: 12px;
  }
  ul li:not(:first-child):last-child::before {
    display: none;
  }
  ul li:only-child::before {
    display: list-item;
    content: ' ';
    position: absolute;
    width: 1.5px;
    background-color: #a5a5a5;
    top: -42px;
    bottom: 7px;
    height: 52px;
    left: -10px;
  }
  ul li::after {
    content: ' ';
    position: absolute;
    left: -10px;
    width: 25px;
    height: 2px;
    background-color: #a5a5a5;
    top: 10px;
  }
}
.tree-card {
  padding: 30px 15px 0 15px;
  overflow-x: auto;
  border-radius: 0 0 5px 5px !important;
  border: 0 !important;
  border-top: 1px solid #e6e6e6 !important;
}
.text-success-link {
  font-weight: 300;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  /* Cores/Auxiliares/Sucesso */
  &:hover {
    font-weight: 500;
    color: #7ad81b !important;
  }
  color: #7ad81b;
}

.text-title-gruop {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #a5a5a5;
}

.text-gruop {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #222222;

  min-width: 145px;
}
.tree-container {
  display: grid !important;
  grid-template-columns: 1fr 1fr 1fr;
}
.tree-main-text {
  margin-right: 16px;
}
.icon-content {
  margin: auto 10px;
  width: 50px;
  justify-content: space-between;
}
.tree-item-container {
  padding-bottom: 30px;
}

.mv-auto {
  margin: auto 0px;
}

.text-success-link {
  cursor: pointer;
  width: fit-content;
}

.text-success-link-widht {
  width: 200px;
}
.text-active {
  background-color: rgba(122, 216, 27, 0.1);
  padding: 3px 12px;
  border-radius: 20px;
  color: #7ad81b;
}
.text-inactive {
  background-color: rgba(216, 35, 27, 0.05);
  padding: 3px 12px;
  border-radius: 20px;
  color: #d8231b;
}

.text-await {
  background-color: rgba(216, 35, 27, 0.05);
  padding: 3px 12px;
  border-radius: 20px;
  color: #F6D93E;
}

.text-processing {
  background-color: rgba(216, 35, 27, 0.05);
  padding: 3px 12px;
  border-radius: 20px;
  color: #3CAFC8;
}

.border-bottom-collapse {
  border-bottom: 1px solid #e6e6e6;
}
.border-bottom-collapse-open {
  border-radius: 0px;
  border-bottom: 1px solid #e6e6e6;
}
