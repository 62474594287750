.main-container-video-image {
  width: 100%;
  margin: auto;
  border: none;
  position: relative;
  text-align: center;
}

.main-container-video {
    width: 100%;
    margin: auto;
    border: none;
    padding-top: 56.25%;
    position: relative;

    iframe{
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		height: 100%;
		width: 100%;
    }
}

.modal-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .modal-close-container {
    top: 15px;
    position: absolute;
    right: 50px;
    z-index: 1;
  }
}

.flex-modal-right {
  display: flex !important;
  justify-content: flex-end;
  backdrop-filter: blur(7px);
  background: rgba(34, 34, 34, 0.7);
}

.flex-modal-right > div {
  //width: 415px !important;
  height: 100% !important;
  margin: 0px 0px !important;
  transition: linear 0.3s;
  transform-origin: right;
  transform: scaleX(0);
  .filter-modal-content {
    height: 100%;
    border-radius: 0;
  }
}

.coupon {
  .dark {
    textarea {
      background: rgba(38, 41, 50, 1);
      color: rgba(168, 169, 173, 1);
    }
    textarea::placeholder {
      color: rgba(168, 169, 173, 1);
    }
  }
}

.button-filter-container {
  display: flex;
  height: fit-content;
}

.filter-container {
  display: flex;
  align-items: center;
  margin-left: 15px;
  height: fit-content;
  margin-top: 15px;
  .filter-content {
    width: fit-content;
  }
}

.alert-error {
  position: absolute;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #e64f49;
}

.passwordIcon {
  width: 20px;
  margin-top: -5px !important;
}

.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

div .pagination-container > li > span {
  border: 1.2px solid #c4c4c4;
  padding: 5px 12px;
  color: #c4c4c4;
  margin-left: -1px;
  cursor: pointer;
  &.disabled {
    cursor: not-allowed;
  }
  &:hover {
    color: #000;
  }
}

div .pagination-container > li > span.previous-pagination {
  border-radius: 4px 0 0 4px;
  color: #000;
}
div .pagination-container > li > span.next-pagination {
  border-radius: 0px 4px 4px 0;
  color: #000;
}

.pagination-container > li.active > span {
  color: #B3140C;
  &:hover {
    color: #D8231B;
  }
}

.checkbox-list {
  display: flex;
  align-items: center;
  vertical-align: baseline;
  margin-right: 30px;
  margin-top: 15px;

  .checkbox-list-label {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    width: 200px;
    color: #828282;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 15px;
  }
  .checkbox-list-name {
    font-size: 16px;
    font-weight: 300;
    line-height: 24px;
    width: 100%;
    color: #828282;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 15px;
  }

  input[type='checkbox'] {
    box-sizing: border-box;
    padding: 0;
    width: 20px;
    height: 20px;
    border: 1.32692px solid #c4c4c4;
    accent-color: rgba(216, 65, 58, 1);
    border-radius: 10px;
  }
}

.div-row-table-column-total-store {
  margin-left: 10px;
}

.exclude-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f8f8;
  border: none;
  border-radius: 5px;
}

.modal__confirmation-text {
  margin-top: 2px;
  text-align: center;
}

@import './assets/scss/app.scss';